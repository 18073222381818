import { LoaderFunctionArgs } from '@remix-run/node'
import { json, Outlet, useLoaderData } from '@remix-run/react'
import { getToast } from 'remix-toast'
import { Toaster, useToast } from '~/components/ui/sonner'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request)
  return json({ toast }, { headers })
}

export default function Auth() {
  const { toast } = useLoaderData<typeof loader>()

  useToast(toast)

  return (
    <>
      <Outlet />
      <Toaster />
    </>
  )
}
